<!-- 关于我们 -->
<template>
	<div class="guanYuWoMen" id="guanYuWoMen">
		<!-- 主体 -->
		<div class="page-content">
			<h-img class="bg" :src="$store.getters.getMenuItem('guanYuWoMen').frontImg"></h-img>
			<div class="nav-list">
				<div
				class="item"
				:class="menuItem_guanYuWoMen_item.menuId == menuItem_guanYuWoMen_active_menuId ? 'active':'normal'"
				v-for="(menuItem_guanYuWoMen_item,index) in menuItem_guanYuWoMen.children"
				:key="index"
				@click="menuItem_guanYuWoMen_item_click(menuItem_guanYuWoMen_item,index)">
					{{menuItem_guanYuWoMen_item.menuName}}
				</div>
			</div>
			<!-- 关于我们 -->
			<div class="section-1" v-if="menuItem_guanYuWoMen_active_menuId == menuItem_guanYuWoMen.children[0].menuId">
				<div class="main-content">
					<div class="company-profile wow animate__animated animate__bounceInUp">
						<div class="title">公司简介</div>
						<div class="english">Company Profile</div>
						<div class="body-content">
							<div class="txt">{{configListAll.companyDesc}}</div>
							<!-- <img class="pic-001" src="/src/assets/001.png" alt=""> -->
							<h-img class="pic-001 scale-1_05" :src="configListAll.companyImg"></h-img>
						</div>
						<div class="statistics-list wow">
							<div class="item">
								<div class="container">
									<div class="txt">客户数量</div>
									<!-- <div class="num">{{configListAll.keHuShuLiang}}</div> -->
									<div v-if="!isNumberScroll" class="num">0</div>
									<div v-else class="num">
										<CountTo
										  :startVal='0'
										  :endVal='numberFormate(configListAll.keHuShuLiang)'
										  :duration='3000'
										/>
										<div>{{configListAll.keHuShuLiang | getUnitFromValue}}</div>
									</div>
								</div>
							</div>
							<div class="item">
								<div class="container">
									<div class="txt">覆盖国家和地区</div>
									<!-- <div class="num">{{configListAll.fuGaiGuoJia}}</div> -->
									<div v-if="!isNumberScroll" class="num">0</div>
									<div v-else class="num">
										<CountTo
										  :startVal='0'
										  :endVal='numberFormate(configListAll.fuGaiGuoJia)'
										  :duration='3000'
										/>
										<div>{{configListAll.fuGaiGuoJia | getUnitFromValue}}</div>
									</div>
								</div>
							</div>
							<div class="item">
								<div class="container">
									<div class="txt">合作运营商</div>
									<!-- <div class="num">{{configListAll.heZuoYunYingShang}}</div> -->
									<div v-if="!isNumberScroll" class="num">0</div>
									<div v-else class="num">
										<CountTo
										  :startVal='0'
										  :endVal='numberFormate(configListAll.heZuoYunYingShang)'
										  :duration='3000'
										/>
										<div>{{configListAll.heZuoYunYingShang | getUnitFromValue}}</div>
									</div>
								</div>
							</div>
							<div class="item">
								<div class="container">
									<div class="txt">支持全语音类别</div>
									<!-- <div class="num">{{configListAll.quanYuYanLeiBie}}</div> -->
									<div v-if="!isNumberScroll" class="num">0</div>
									<div v-else class="num">
										<CountTo
										  :startVal='0'
										  :endVal='numberFormate(configListAll.quanYuYanLeiBie)'
										  :duration='3000'
										/>
										<div>{{configListAll.quanYuYanLeiBie | getUnitFromValue}}</div>
									</div>
								</div>
							</div>
							<div class="item">
								<div class="container">
									<div class="txt">全国布局子公司</div>
									<!-- <div class="num">{{configListAll.companyZigongsi}}</div> -->
									<div v-if="!isNumberScroll" class="num">0</div>
									<div v-else class="num">
										<CountTo
										  :startVal='0'
										  :endVal='numberFormate(configListAll.companyZigongsi)'
										  :duration='3000'
										/>
										<div>{{configListAll.companyZigongsi | getUnitFromValue}}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="pic-list wow animate__animated animate__bounceInUp">
						<div class="row" v-if="configListAll.companyHj">
							<h-img class="pic" hoverClass="scale-1_05" :src="item" v-for="(item,index) in configListAll.companyHj.split(',')" :key="index"></h-img>
						</div>
					</div>
				</div>
			</div>
			<!-- 公司荣誉 -->
			<div class="section-2" v-if="menuItem_guanYuWoMen_active_menuId == menuItem_guanYuWoMen.children[1].menuId">
				<div class="main-content">
					<div class="list">
						<div class="item item-1 translate-y-10 box-shadow-gray" v-for="(item,index) in rongYuZhanShi" :key="index">
							<!-- <img src="" alt="" class="pic-black"> -->
							<h-img class="pic-black" :src="item.contentImg"></h-img>
							<div class="title">{{item.contentTitle}}</div>
						</div>
						<!-- <div class="item item-2">
							<img src="" alt="" class="pic-black">
							<div class="title">2020年获得合肥市数据资源局授予的合肥</div>
						</div>
						<div class="item item-3">
							<img src="" alt="" class="pic-black">
							<div class="title">2020年获得合肥市数据资源局授予的合肥</div>
						</div>
						<div class="item item-4">
							<img src="" alt="" class="pic-black">
							<div class="title">2020年获得合肥市数据资源局授予的合肥</div>
						</div>
						<div class="item item-5">
							<img src="" alt="" class="pic-black">
							<div class="title">2020年获得合肥市数据资源局授予的合肥</div>
						</div>
						<div class="item item-6">
							<img src="" alt="" class="pic-black">
							<div class="title">2020年获得合肥市数据资源局授予的合肥</div>
						</div> -->
					</div>
				</div>
			</div>
			<!-- 公司团队 -->
			<div class="section-3" v-if="menuItem_guanYuWoMen_active_menuId == menuItem_guanYuWoMen.children[2].menuId">
				<div class="main-content">
					<div class="list">
						<div class="item item-1" v-for="(item,index) in teamList" :key="index">
							<div class="pic-box">
								<!-- <img src="" alt="" class="pic-black"> -->
								<h-img class="pic-black" :src="item.userAvatar"></h-img>
							</div>
							<div class="content">
								<div class="name">{{item.userName}}</div>
								<div class="position">{{item.position}}</div>
								<div class="description">{{item.description}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 联系我们 -->
			<div class="section-4" v-if="menuItem_guanYuWoMen_active_menuId == menuItem_guanYuWoMen.children[3].menuId">
				<div class="main-content">
					<div class="left-block">
						<div class="company-name">{{configListAll.companyName}}</div>
						<div class="english">{{configListAll.companyNameEnglish}}</div>
						<div class="line"></div>
						<div class="tip">关注一建优采，获取更多商机信息</div>
						<div class="qr-box">
							<div class="item item-1">
								<!-- <img src="" alt="" class="pic-block"> -->
								<h-img class="pic-block" :src="configListAll.website_afficial"></h-img>
								<div class="description">微信公众号</div>
							</div>
							<div class="item item-2">
								<h-img class="pic-block" :src="configListAll.website_applet"></h-img>
								<div class="description">微信小程序</div>
							</div>
							<div class="item item-3">
								<h-img class="pic-block" :src="configListAll.website_weibo"></h-img>
								<div class="description">微博</div>
							</div>
						</div>
						<div class="contact-block">
							<div class="left">
								<div class="label">全国统一咨询热线</div>
								<div class="value">{{configListAll.companyQgrx}}</div>
								<div class="label label-3">服务时间：24小时全天服务</div>
							</div>
							<div class="right">
								<div class="label">前台电话</div>
								<div class="value">{{configListAll.qtphone}}</div>
							</div>
						</div>
					</div>
					<div class="middle-block">
						<div class="item">
							<div class="label">地址：</div>
							<div class="content">{{configListAll.website_address}}</div>
						</div>
						<div class="item">
							<div class="label">电话：</div>
							<div class="content">{{configListAll.website_phone}}</div>
						</div>
						<div class="item">
							<div class="label">邮箱：</div>
							<div class="content">{{configListAll.website_email}}</div>
						</div>
						<div class="item">
							<div class="label">邮编：</div>
							<div class="content">{{configListAll.postcode}}</div>
						</div>
					</div>
					<div class="right-block">
						<div class="label">前台电话</div>
						<div class="value">{{configListAll.qtphone}}</div>
						<div class="line"></div>
						<div class="label">全国统一咨询热线</div>
						<div class="value">{{configListAll.companyQgrx}}</div>
						<div class="label label-3">服务时间：24小时全天服务</div>
					</div>
					<div class="qr-box-bottom">
						<div class="item item-1">
							<h-img class="pic-block" :src="configListAll.website_afficial"></h-img>
							<div class="description">微信公众号</div>
						</div>
						<div class="item item-2">
							<h-img class="pic-block" :src="configListAll.website_applet"></h-img>
							<div class="description">微信小程序</div>
						</div>
						<div class="item item-3">
							<h-img class="pic-block" :src="configListAll.website_weibo"></h-img>
							<div class="description">微博</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapState} from 'vuex'
import {ContentList} from '@/apis/wenZhangLanMu.js'
import {TeamList} from '@/apis/gongSiTuanDui.js'
export default {
	data() {
		return {
			menuItem_guanYuWoMen: {},
			menuItem_guanYuWoMen_active_menuId: '',
			rongYuZhanShi: [],
			teamList: [],
			isNumberScroll: false,

		};
	},
	filters: {
		getUnitFromValue(val) {
			 var num = parseFloat(val)
			 return val.replace(num, '').replace(/\./, '');
		 }
	},
	computed: {
		...mapState(['configListAll'])
	},
	watch: {
		erJiMenu_path(newval,oldval) {
			if(this.menuItem_guanYuWoMen.children.some(val => newval == val.path)){
				document.querySelector('#guanYuWoMen').scrollIntoView({
					behavior: 'smooth'
				})
				this.getMenuListForGuanYuWoMen()
			}
		}
	},
	created() {
		console.log("this.erJiMenu_path: ",this.erJiMenu_path);
		this.getMenuListForGuanYuWoMen()
	},
	mounted() {
      new WOW({
        boxClass: 'wow',
        animateClass: 'animated',
        offset: this.isMobile ? 0:150,
        mobile: true,
        live: false
      }).init();
	  
	  let numberScroll = setInterval(() => {
	  	if ($('.statistics-list').hasClass("animated")) {
	  		// console.log('333: ',333);
	  		this.isNumberScroll = true
	  		console.log('this.isNumberScroll: ',this.isNumberScroll);
	  		clearInterval(numberScroll)
	  	}
	  },1000)
  },
	methods: {
		getMenuListForGuanYuWoMen() {
			this.menuItem_guanYuWoMen = {...this.$store.getters.getMenuItem('guanYuWoMen')}
			this.menuItem_guanYuWoMen.children = [...this.menuItem_guanYuWoMen.children]
			if (this.erJiMenu_path) {
				let erJiMenu_item = this.$store.getters.getMenuItem(this.erJiMenu_path)
				let find_item = this.menuItem_guanYuWoMen.children.find(val => val.menuId == erJiMenu_item.menuId)
				if (find_item) {
					this.menuItem_guanYuWoMen_active_menuId = find_item.menuId
					if (erJiMenu_item.menuName == "关于我们") {

					} else if(erJiMenu_item.menuName == "公司荣誉") {
						this.getContentListForRongYuZhanShi()
					} else if(erJiMenu_item.menuName == "公司团队") {
						this.getTeamList()
					} else if(erJiMenu_item.menuName == "联系我们") {

					}
				} else {
					this.menuItem_guanYuWoMen_active_menuId = this.menuItem_guanYuWoMen.children[0].menuId
				}
			} else {
				this.menuItem_guanYuWoMen_active_menuId = this.menuItem_guanYuWoMen.children[0].menuId
			}
		},
		getContentListForRongYuZhanShi() {
			ContentList(2101, '').then(res => {
				this.rongYuZhanShi = res.data
			})
		},
		getTeamList() {
			TeamList().then(res => {
				this.teamList = res.data
			})
		},
		menuItem_guanYuWoMen_item_click(menuItem_guanYuWoMen_item,index) {
			if (this.menuItem_guanYuWoMen_active_menuId == menuItem_guanYuWoMen_item.menuId) {
				return
			} else {
				this.menuItem_guanYuWoMen_active_menuId = menuItem_guanYuWoMen_item.menuId

				if (menuItem_guanYuWoMen_item.menuName == '关于我们') {

				} else if(menuItem_guanYuWoMen_item.menuName == '公司荣誉') {
					this.getContentListForRongYuZhanShi()
				} else if(menuItem_guanYuWoMen_item.menuName == '公司团队') {
					this.getTeamList()
				} else if(menuItem_guanYuWoMen_item.menuName == '联系我们') {

				}
			}
		},
	}
};
</script>

<style lang="scss" scoped>
	@import './guanYuWoMen.scss';
	@import './guanYuWoMenMedia.scss';
</style>
