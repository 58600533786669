// 公司团队
import request from '@/util/request.js'

// 团队列表
export function TeamList() {
	return request({
		method: 'post',
		url: `/api/team/list`
	})
}
